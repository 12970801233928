<template>
    <div class="flex justify-center w-full common" style="background:#F5F5F5">
        <div class="box">
            <div class="flex items-center justify-between py-3 navbar">
                <div class="flex flex-wrap items-center">
                    <div
                        class="menu_item sm:mr-1"
                        v-for="(item, index) in menu"
                        :key="index"
                        @click="changeType(index)"
                    >
                        <div
                            class="flex items-center justify-center cursor-pointer child_item"
                            :class="activeIndex == index ? 'menu_active text-white' : ''"
                        >{{ item.categoryName }}</div>
                    </div>
                </div>
                <!-- <div class="flex items-center pc_show">
                    <div class="mr-6">推荐</div>
                    <div class="mr-6">新品</div>
                    <div>排序</div>
                </div>-->
            </div>
            <div class="flex flex-wrap content">
                <div v-if="porducts.length > 0" class="flex flex-wrap w-full">
                    <div
                        class="flex justify-center mb-1 sm:mb-3 goods"
                        v-for="(item, index) in porducts"
                        :key="index"
                    >
                        <div class="duration-500 bg-white cursor-pointer good">
                            <!-- <div class="absolute flex items-center collection">
                                <img src="../../../../assets/images/common/collection.png" alt />
                                <img
                                    src="../../../../assets/images/common/collection_active.png"
                                    alt
                                />
                                <div class="flex items-center pl-1">收藏</div>
                            </div>-->
                            <!-- <div
                            class="absolute flex items-center justify-center text-white bg"
                            v-if="index < 2"
                            >{{ index == 0 ? '热卖' : '新品' }}</div>-->
                            <div class="good_box z-1" @click="goLink(item)">
                                <div class="flex items-center justify-center w-full">
                                    <!-- <img src="../../../../assets/images/common/goods.png" alt /> -->
                                    <img :src="item.masterUrl" alt />
                                </div>
                                <div>
                                    <div class="title">{{ item.commodityName }}</div>
                                    <div
                                        class="font-bold text-primary price"
                                    >{{ item.discountPrice }}元</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center justify-center w-full py-32" v-else>
                    <div class="text-center text-gary-400">暂无商品</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            menu: [],
            activeIndex: 0,
            // list: ['', '', '', '', '', '', '', '', '', '', '', '',],
            code: '',
            parentId: "",
            porducts: []
        };
    },
    components: {

    },
    created() {
        this.getQuery()
    },
    watch: {
        '$route': function () {
            this.getQuery()
        },
        activeIndex: function () {
            this.getCategoryProduct(this.menu[this.activeIndex].categoryId)
        }
    },
    methods: {
        // 获取参数
        getQuery() {
            this.code = this.$route.query.code
            this.parentId = this.$route.query.parentId
            this.getClassification()
        },
        changeType(index) {
            this.activeIndex = index
        },
        judgeClass(index) {
            let result = ""
            if (index > 2) {
                (index + 1) % 2 == 1 ? result = "justify-center" : (index + 1) % 3 == 0 ? result = "justify-end" : ''
            } else {
                index == 1 ? result = "justify-center" : index == 2 ? result = "justify-end" : ''
            }
            return result
        },
        // 获取二级分类
        getClassification() {
            let url = this.$requestPath.getCommodityClassify + `?parentId=${this.parentId}&code=${this.code}`
            this.$request({
                method: "get",
                url
            }).then(res => {
                // console.log('获取二级分类', res)
                if (res.code == 0) {
                    this.menu = []
                    this.menu.push(...res.data)
                    if (this.menu.length > 0) {
                        this.getCategoryProduct(this.menu[0].categoryId)
                    } else {
                        this.porducts = []
                    }
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '获取二级分类失败' })
                }

            }).catch(error => {
                console.log('获取二级分类错误', error)
            })
        },
        //  获取分类商品
        getCategoryProduct(classifyId) {
            let url = this.$requestPath.getClassifyProducts + `?classifyId=${classifyId}`
            this.$request({
                method: "get",
                url
            }).then(res => {
                console.log('获取分类商品', res)
                if (res.code == 0) {
                    this.porducts = []
                    this.porducts.push(...res.data)
                    // this.menu = []
                    // this.menu.push(...res.data)
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: '获取商品失败' })
                }

            }).catch(error => {
                console.log('获取商品错误', error)
            })
        },
        // 跳转商品外链
        goLink(item) {
            this.$router.push({
                path: "/index/activity", query: {
                    pageType: "product",
                    commodityId: item.commodityId
                }
            })
        }
    },
};
</script>

<style lang='scss' scoped>
.box {
    width: 100vw;
    max-width: var(--max-width);
    margin: 0 var(--margin-base);
    min-height: var(--min-height);
}

.collection {
    z-index: 5;
}

@media screen and(max-width:640px) {
    .pc_show {
        display: none;
    }
    .goods {
        width: 50%;

        .good {
            margin: 1%;
            width: 96%;
            background: white;
            display: flex;
            justify-content: center;
            .good_box {
                width: 95%;
                z-index: 1;
                justify-content: center;
                padding-bottom: 0.375rem;
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .menu_item {
        // height: 1.5625rem;
        padding: 0 0.25rem;
        // width: 25%;
        margin-bottom: 0.25rem;
        .child_item {
            padding: 0.25rem 0.25rem;
            background: #e4e4e4;
            border-radius: 1.125rem;
            font-size: 0.75rem;
            color: #666666;
        }
    }
    .good_box {
        img {
            width: 5rem;
        }
    }
    .collection {
        display: none;
    }
    .title {
        font-size: 0.75rem;
    }
    .price {
        font-size: 0.75rem;
        white-space: nowrap;
    }
    .bg {
        z-index: 5;
        right: -1.75rem;
        top: 0;
        height: 1.5625rem;
        background: var(--primary);
        width: 5.125rem;
        transform: rotate(45deg);
        font-size: 0.75rem;
    }
}
@media screen and(min-width: 640px) {
    .box {
        .goods {
            width: 33.33%;
            height: 0;
            padding-bottom: 33.33%;
            overflow: hidden;
            .good {
                // background: red;
                overflow: hidden;
                margin: 2%;
                width: 96%;
                padding-bottom: 96%;
                background: white;
                position: relative;
                .good_box {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .pc_show {
        display: flex;
    }
    .menu_item {
        // height: 1.5625rem;

        margin-bottom: 0.5rem;
        .child_item {
            padding: 0.5rem 1.875rem;
            background: #e4e4e4;
            color: #666666;
            border-radius: 1.125rem;
            font-size: 1rem;
        }
    }

    .collection {
        left: 1rem;
        top: 1rem;
        img {
            width: 1rem;
        }
        div {
            padding-top: 0.125rem;
        }
    }
    .title {
        font-size: 1rem;
        margin-top: 0.625rem;
    }
    .bg {
        z-index: 5;
        right: -5rem;
        top: 0.3125rem;
        height: 30px;
        background: var(--primary);
        width: 12.5rem;
        transform: rotate(45deg);
    }
    .good:hover {
        box-shadow: 0px 0px 0.8125rem 0px rgba(0, 0, 0, 0.1),
            0px 0px 0.5625rem 0px rgba(0, 0, 0, 0.05);
    }
}
.menu_active {
    background: var(--primary) !important;
    color: white !important;
}
</style>